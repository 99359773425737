import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import styled from "@emotion/styled"

import Layout from "../components/layout"
import Card from "../components/tagCard"
import Button from "../components/button"
import SEO from "../components/seo"

const breakpoints = [600, 700, 1000]

const mq = breakpoints.map(
  bp => `@media (min-width: ${bp}px)`
)

const Wrapper = styled.div`
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
`

const TagsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-gap: 2rem;
  margin: 0 auto;
  padding: 0 1rem;
  ${mq[0]} {
    grid-template-columns: repeat(3, 1fr);
  }
  ${mq[2]} {
    grid-template-columns: repeat(4, 1fr);
  }
`

const ButtonWrapper = styled.div`
  margin-top: 1rem;
  align-self: center;
`

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`

  const siteTitle = " | Groove Café"
  const name = tag.concat(siteTitle)

  return (
    <Layout>
      <SEO title={name} />
      <Wrapper>
        <h1>{tagHeader}</h1>
        <TagsContainer>
          {edges.map(({ node }) => {
            const { path, title, date, author, featuredImage } = node.frontmatter
            return (
              <Card
                title={title}
                image={featuredImage.childImageSharp.fluid}
                date={date}
                author={author}
                link={path}
              />
            )
          })}
        </TagsContainer>
        <ButtonWrapper>
          <Button
            text="all tags"
            link="/tags"
          />
        </ButtonWrapper>
      </Wrapper>
    </Layout>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            path
            date
            author
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
