import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import colors from "./colors"

const breakpoints = [600, 1000]

const mq = breakpoints.map(
  bp => `@media (min-width: ${bp}px)`
)

const CardOutline = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  border-style: solid;
  border-width: 1px;
  border-color: grey;
  border-radius: 5px;
  background-color: ${colors.lightyellow};
  box-shadow: -5px 5px ${colors.navy};
  height: auto;
  text-align: left;
  width: 130px;
  &:hover,&:focus {
    color: ${colors.navy};
    background-color: ${colors.pink};
  }
  ${mq[0]}{
    padding: 5px;
    width: 150px;
  }
  ${mq[1]}{
    padding: 5px;
    width: 175px;
  }
`

const Image = css`
  width: 120px;
  margin: 0 auto;
  height: 75px;
  margin-top: 5px;
  align-self: center;
  ${mq[0]}{
    width: 100%;
    height: 75px;
  }
  ${mq[1]}{
    width: 100%;
    height: 100px;
  }
`

const Title = styled.h4`
  padding: 8px;
  color: ${colors.navy};
  font-family: Noto Sans;
  font-weight: 400;
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 0px;
  ${mq[1]}{
    font-size: 18px;
    padding: 10px;
  }
`

const AuthorDate = styled.h6`
  padding: 0 8px;
  margin-top: 10px;
  margin-bottom: 0px;
  line-height: 1.2;
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 700;
  ${mq[1]}{
    font-size: 14px;
    padding: 0 10px;
  }
`

const LinkStyle = css`
    text-decoration: none;
`

const Card = props => (
    <Link to={props.link} css={LinkStyle}>
      <CardOutline>
        <Img fluid={props.image} title={props.title} css={Image} alt={props.alt} />
        <AuthorDate>{props.date}<br />by {props.author}</AuthorDate>
        <Title>{props.title}</Title>
      </CardOutline>
    </Link>
)

export default Card
